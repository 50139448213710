import React from "react"

/* Header */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  PhoneIcon,
  XIcon,
  MailIcon,
} from '@heroicons/react/outline'
import { UserIcon } from '@heroicons/react/solid'
import { StaticImage } from "gatsby-plugin-image"


/*
Header
*/


// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

export default function KdpHeader() {
  return (
    <Popover className="relative bg-white">
      
      <div className="absolute inset-0 shadow z-20 pointer-events-none" aria-hidden="true" />
      <div className="relative z-20">
        <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-4 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
          <div>
            <a href="/" className="flex">
              <span className="sr-only">blicko</span>
              <StaticImage 
                    src="../../images/logo__blicko.png"
                    alt="blicko"
                    width={140}
                />
            </a>
          </div>

          <div className="ml-20 -mr-2 -my-2 text-gray-400 md:hidden">
            <a href="https://app.blicko.fr/login" target="_blank" rel="noreferrer" className="">
              <UserIcon className="h-6 w-6" aria-hidden="true" />
            </a>
          </div>

          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3333e6]">
              <span className="sr-only">Ouvrir le menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-4">
            
            </Popover.Group>
            <div className="flex items-center md:ml-12">
              <a
                  href="tel:0428295904"
                  className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#3333e6] hover:bg-blue-700"
                >
                  <PhoneIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />&nbsp;&nbsp;04 28 29 59 04
                </a>
            </div>
          </div>
        </div>
      </div>


      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                  <StaticImage 
                        src="../../images/logo__blicko.png"
                        alt="blicko"
                        width={140}
                    />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3333e6]">
                    <span className="sr-only">Fermer</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 sm:mt-8">

              </div>
            </div>




            <div className="py-6 px-5">
              <div className="mt-6">
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  <a
                    href="tel:0428295904"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#3333e6] hover:bg-blue-700"
                  >
                    <PhoneIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />&nbsp;&nbsp;04 28 29 59 04
                  </a>
                </p>
                
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  <a href="#formulaire" className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-600 hover:bg-orange-700">
                  <MailIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />&nbsp;&nbsp;Contactez-nous
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>





    </Popover>
  )
}