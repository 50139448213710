/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { encode } from 'js-base64';
import LandingForm from "../../components/contacts/landingForm"

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const encryptedMail= encode("contact@blicko.fr");
const moyen = "Formulaire blicko Landing";
  
  export default function Section4() {
    return (
      <div id="formulaire" className="bg-gray-100">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="mt-20 text-3xl font-extrabold tracking-tight sm:text-4xl">Contacter notre plombier blicko</h2>
              <p className="text-xl text-gray-500">
                Vous connaissez une galère avec votre installation de plomberie. Fuite d’eau, bouchon de canalisation, chauffe-eau en panne… 
              </p>
              <p className="text-xl text-gray-500">
                Contactez-nous sans attendre pour obtenir une solution rapide et efficace au <br /> <a href="tel:0428295904"><span className="text-[#3333e6] font-bold">04 28 29 59 04</span></a>
              </p>
            </div>
            <div className="lg:col-span-2">
              <LandingForm moyen={moyen} destinataire={encryptedMail} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
